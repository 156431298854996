import React from 'react';

interface Service {
  name: string;
  description: string;
}

const services: Service[] = [
  {
    name: "Имплантация",
    description: "Восстановление утраченных зубов с помощью современных имплантатов."
  },
  {
    name: "Ортодонтия",
    description: "Исправление прикуса и выравнивание зубов с использованием брекетов и элайнеров."
  },
  {
    name: "Отбеливание",
    description: "Профессиональное отбеливание зубов для яркой и красивой улыбки."
  }
  // Add more services as needed
];

const Services: React.FC = () => {
  return (
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Наши услуги</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-3 text-gray-800">{service.name}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;