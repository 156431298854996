import React from 'react';

interface Doctor {
  name: string;
  specialty: string;
  imageUrl: string;
}

const doctors: Doctor[] = [
  {
    name: "Доктор Арнур Айтжанович",
    specialty: "Хирург-Имплантолог",
    imageUrl: "/images/doctors/Arnur Aitzhanovich small.jpeg"
  },
  {
    name: "Доктор Ринат Ержанович",
    specialty: "Ортопед",
    imageUrl: "/images/doctors/Rinat Erzhanovich.jpeg"
  },
  {
    name: "Доктор Бахыт Ахатовна",
    specialty: "Врач-Терапевт",
    imageUrl: "/images/doctors/Bakhyt Akhatovna.png"
  }
];

const Doctors: React.FC = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Наши врачи</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {doctors.map((doctor, index) => (
            <div key={index} className="bg-gray-50 rounded-lg shadow-md overflow-hidden">
              <div className="h-96 overflow-hidden">
                <img 
                  src={doctor.imageUrl} 
                  alt={doctor.name} 
                  className="w-full h-full object-cover object-center"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{doctor.name}</h3>
                <p className="text-gray-600">{doctor.specialty}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Doctors;