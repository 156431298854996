import React from 'react';
import './App.css'; // Make sure this line is present
import Header from './components/Header';
import About from './components/About';
import ContactForm from './components/ContactForm';
import Reviews from './components/Reviews';
import Doctors from './components/Doctors';
import Services from './components/Services';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <main className="main-content">
        <About />
        <Doctors />
        <Services />
        <Reviews />
        <ContactForm />
      </main>
    </div>
  );
}

export default App;