import React from 'react';

interface Review {
  name: string;
  rating: number;
  text: string;
}

const reviews: Review[] = [
  {
    name: "Руслан П",
    rating: 5,
    text: `Прекрасная клиника с прекрасными специалистами! 
    Отдельно хочу отметить Турсунова Арнура Айтжановича, 
    гуру хирург-имплантолог, очень профессиональный и грамотный доктор, 
    подскажет, расскажет и объяснит. 
    Операция по установке имплантов прошла замечательно 👍🏼`
  },
  {
    name: "​Бактжамал Молдагалиева",
    rating: 5,
    text: `Здравствуйте мне повезло с такими врачами,
    как красиво сделали зубы подлечили и протезировали.
    Спасибо Ринат Ержановичу, руки у него золотые,
    все прошло без всяких осложнений.
    Рекомендую всем не пожалеете❤❤❤❤❤`
  },
  {
    name: "​Сауле Сабитов",
    rating: 5,
    text: `Замечательный врач Бахыт Ахатовна, отличный специалист,
    прекрасно ладит с детьми, лечили зубы 3-х летнему ребенку,
    все быстро, профессионально и качественно, ребенок совсем не плакал.
    Теперь всей семьей только к ней`
  }
];

const Reviews: React.FC = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Отзывы наших пациентов</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {reviews.map((review, index) => (
            <div key={index} className="bg-gray-50 rounded-lg shadow-md p-6">
              <div className="flex items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-800 mr-2">{review.name}</h3>
                <div className="text-yellow-400">
                  {'★'.repeat(review.rating)}
                  {'☆'.repeat(5 - review.rating)}
                </div>
              </div>
              <p className="text-gray-600">{review.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Reviews;