import React from 'react';

const About: React.FC = () => {
  return (
    <section className="py-12 bg-gray-50 relative">
      <div 
        className="absolute inset-0 bg-cover bg-top md:bg-center md:bg-fixed" 
        style={{ 
          backgroundImage: "url('/images/background/about-background.jpg')",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col xl:flex-row">
          <div className="w-full xl:w-1/2">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">О нашей клинике</h2>
            <div className="bg-white bg-opacity-90 p-6 rounded-lg shadow-lg">
              <p className="text-lg mb-4 text-gray-700">
                Клиника Турсунова - это современный стоматологический центр, оснащенный передовым оборудованием и укомплектованный опытными специалистами.
              </p>
              <p className="text-lg mb-4 text-gray-700">
                Наша миссия - обеспечить высококачественное лечение и профилактику стоматологических заболеваний, используя инновационные методы и индивидуальный подход к каждому пациенту.
              </p>
              <ul className="list-disc list-inside mb-4 text-gray-700">
                <li className="mb-2">Команда квалифицированных врачей</li>
                <li className="mb-2">Современное оборудование</li>
                <li className="mb-2">Комфортная атмосфера</li>
                <li className="mb-2">Индивидуальный подход к каждому пациенту</li>
              </ul>
              <p className="text-lg text-gray-700">
                Доверьте здоровье вашей улыбки профессионалам Клиники Турсунова!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;